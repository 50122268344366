<template>
  <section class="h-100">

    <div class="m-auto text-center mb-7">
      <img
        src="@/assets/images/menu_2.svg"
        class="w-full"
      >

      <img
        src="@/assets/images/setup_profile/step_5.svg"
        class="mx-auto mt-5"
      >
      <span class="text-neutral">Step 5 of 10</span>
    </div>

    <div class="flex h-screen text-center" style="height:80vh;">
      <div class="m-auto pb-10 w-2/3">

        <div class="m-auto" style="max-width: 800px;">
          <h2 class="mb-9">Great job so far!</h2>

          <img
            class="m-auto mb-5"
            src="@/assets/images/undraw/suburbs.svg"
          >

          <h4 class="text text-2xl mb-6">Next step: Life experiences</h4>

          <p class="mb-6 text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas pretium aenean pharetra magna ac placerat vestibulum lectus mauris. Adipiscing enim eu turpis egestas pretium aenean pharetra magna ac. Vel pharetra vel turpis nunc eget. Felis eget nunc lobortis mattis aliquam faucibus purus in. Malesuada fames ac turpis egestas sed tempus. Purus gravida quis blandit turpis cursus in hac. </p>

        </div>

        <div style="margin-top: 60px;">
          <router-link
            :to="{name: 'v2.profile.places'}"
            class="text-primary float-left"
          >
            <img
              src="@/assets/images/icons/ic_chevron_left_blue.svg"
              class="inline"
            >
            Back
          </router-link>

          <router-link
            :to="{name: 'registration.v2'}"
            class="btn btn-primary-outline btn-large align-self-center me-auto mr-10"
          >
            Finish Later
          </router-link>

          <router-link
            :to="{name: 'v2.profile.images'}"
            class="btn btn-primary btn-large align-self-center me-auto"
          >
            Onwards
          </router-link>

          <router-link
            :to="{name: 'v2.profile.images'}"
            class="text-primary float-right"
          >
            Skip

            <img
              src="@/assets/images/icons/ic_chevron_right_blue.svg"
              class="inline"
            >
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'GreatJob',
  mounted() {
  }
}
</script>

<style scoped>
h3 {
  font-family: 'Helvetica';
  font-size: 54px;
  line-height: 70px;
  color: #FFFFFF;
  letter-spacing: 1px;
  font-weight: 300;
}
h3 > strong {
  font-weight: bold;
}
</style>
